@import './components/layout/GlobalStyles';
/*fonts*/


html {
    overflow-x: hidden;
}


body{
    padding: 0px !important;
}

/*link colors*/
a {
    color: blue;
}

a:hover {
    color: slateblue;
}

/*global setting for disabled attribute on div*/
div[disabled] {
    pointer-events: none;
    opacity: 0.6;
    background-color: #ccc
}

.hidden {
    display: none !important;
}

textarea{
    resize: none; //so nobody can overflow the html file (through some wacky means of dragging the textarea box for virtual miles)
}

/*=================
    CONTROLS
  ===============*/

/*note: does not work on identity pages*/
.btn {
    background-color: $interactable;
    border-color: $off-white;
}

.btn:hover, .btn:active {
    background-color: $interactable-light;
    border-color: $off-white;
}

.btn-primary, .btn-primary:active {
    background-color: $interactable;
    border-color: $off-white;
}

label > input[type=checkbox]:checked {
    /*input nested inside of label, meaning label functions as a clickable element for the input*/
    color: blue;
}

input[type=checkbox]:checked + label{
    color: blue;
}

/* Customize the label (the container) */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.form-group.required .control-label:after{
    content:"*";
    color:red;
}

/*custom arrows from ashley's design using fa icon*/
.pagin_arrow {
    font-size: 2rem;
    margin: 0 auto;
}

.pagin_arrow_right_only {
    font-size: 2rem;
    margin-left: 50%;
}

.pagin_arrow_left_only {
    font-size: 2rem;
    margin-right: 50%;
}

#myOrdersPrintTable{
    display: none;
}

@media print{

    html, body {
        height: 100vh;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }

    /*layout*/
    .sidebar{
        display: none;
    }

    .topbar{
        display: none;
    }

    .row-foot{
        display: none;
    }

    .deliveries{
        display: none;
    }

    #myOrders {
        display: none;
    }

    #myOrdersPrintTable{
        display: block;
    }

}