// Imports
@import 'GlobalStyles';

.sidebar {
    position: fixed;
    height: 100%;
    min-width: 285px;
    width: 285px;
    background-repeat: repeat-y;
    background-size: cover;
    color: $main-font-color;
    z-index: 999;
    left: -250px;
}

.sidebar-contents {
    float: left;
    height: 100%;
    width: 250px;
    /*background: linear-gradient(to right, rgba(0,0,0,0) 95%, rgba(0, 0, 0, .5) 100%), url("/images/metalFinish.jpg");
    background-repeat: repeat-y;
    background-size: cover;*/
    background: $menu;
    color: $main-font-color;
}


.sidebar.is-open {
    left: 0px;
    /*transform: translateX(0);
    transition: .5s;*/
}

.sidebar-header {
    height: 70px;
}

.sidebar-header h3 {
    color: $main-font-color;
    padding: 1em;
    text-align: center;
}

.sidebar-header span {
    color: azure;
    font-size: x-large;
    z-index: 4441;
    text-align: center;
}

.sidebar-header span:hover {
    color: #9242fa
}

.sidebar ul p {
    color: $main-font-color;
    padding: 10px;
}

.sidebar-subheader {
    font-size: 1rem;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-variant: small-caps;
}

.userName{
    font-style: italic;
}

.userName.admin {
    color: #BD5FDE;
}

.menu-open {
    background: $medium-bgcolor;
}

.menu-icon{
    color: $footer-dark;
}

.menu-icon:hover{
    color: $white;
}

.sidenav-item {
    border-radius: 1.5rem !important;
    margin: 4px 10px;
    font-family: 'Lato Heavy';
}

.sidenav-link{
    color: $interactable;
    border-radius: 1.5rem;
    background-color: $menu-btn;
}

.sidenav-link:hover{
    color: $white;
    background-color: $interactable-light;
}

.sidenav-link-selected {
    color: $white;
    border-radius: 1.5rem;
    background-color: $interactable;
}

.sidenav-link-selected svg{
    color: $white;
}

.sidenav-link-selected:hover{
    color: $white;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

/*resources tab header item hover... items are rounded.*/
.resourceHeader.nav-item:hover {
    color: $medium-bgcolor !important;
    background: $main-font-color !important;
    border-radius: 0.5em;
}

.nav-item:hover {
    color: $mute-font-color !important;
    text-shadow: rgba(0,0,0,0.5) -1px 0, rgba(0,0,0,0.3) 0 -1px, rgba(255,255,255,0.5) 0 1px, rgba(0,0,0,0.3) -1px -2px;
    background: rgba(255, 255, 255, .5) !important;
}

.items-menu {
    color: $main-font-color;
    background: $medium-bgcolor;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: .5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.account-info {
    height: 75px;
    display: block;
    text-align: center;
    word-break: break-all;
    width: 230px;
    margin: 0 auto;
    font-size: large;
}

/*.account-image {
    border: 3px solid silver;
    border-radius: 50%;
}*/

.side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
    text-overflow: ellipsis;
}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: $medium-bgcolor; /*medium blue*/
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: $medium-bgcolor;
}

.sidebar-transparency {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 998;
    will-change: opacity;
}

.sidebar-transparency:hover {
    background-color: rgba(0,0,0,0.2);
}

.hidden {
    display: none;
}

/*====================
    mobile swipe
====================*/

.sidebar-mobile-swipe {
    height: 100%;
    background-color: red;
    opacity: 0;
    width: 70px;
    right: 0;
    position: absolute;
}