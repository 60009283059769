@import './layout/GlobalStyles';

#news {
    user-select: none;
    background-color: #272727;
    height: 1400px;
    /*min-width: 500px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
}
/*News Header*/
#news-header {
    padding: 0px 1px 0px 1px;
    top: 0;
    width: inherit;
    height: 50px;
    line-height: 15px;
    text-align: center;
    font-size: 2rem;
    text-shadow: 1px 1px #252525;
    border-bottom: 2px solid #B6A791; /*2 shades darker than newspaper color*/
    border: 1px solid black;
}

#news-body {
    position: relative;
    background-color: darkgray;
    height: 100%;
    width: 100%;
    border: 1px solid black;
}

#news-body:first-child {
    border-top: 0px solid transparent !important;
}

#news-header > h2 {
    text-align: center;
}

#news-footer {
    height: 60px;
    width: 100%;
    background-color: darkgray;
    overflow: hidden;
    border: 1px solid black;
    display: inherit;
    align-items: center;
    /*position: absolute;
    bottom: 0;*/
}

#news-arrow-box {
    min-width: 280px;
    margin: 0 auto;
    display: inherit;
    left: 22%;
}

.news-button {
    font-size: medium;
    text-align: center;
    bottom: 0;
    position: absolute;
    margin: 5px;
}

.news-delete-button {
    font-size: x-small;
    text-align: center;
    margin: 5px;
    height: 27px;
    position: absolute;
    right: 5px;
    background: rgb(73,73,73);
    background: linear-gradient(20deg, rgba(73,73,73,1) 0%, rgba(9,9,121,1) 27%, rgba(190,190,190,1) 80%);
}

.newsPost {
    border-top: 1px solid black;
    width: inherit;
    overflow: hidden;
    padding: 0 5px;
    max-height: 418px;
    margin-bottom: 12px;
    background-color: white;
}

.newsPost-title {
    border-bottom: 1px solid #e0e0e0;
    min-height: 35px;
    position: relative;
}

.newsPost-title > h4 {
    text-align: left;
    margin-left: 10px;
    float: left;
    position: absolute;
}

.newsPost-body {
    display: flex;
    flex-direction: column;
}

.newsPost-body-content {
    /*overflow-y: scroll; for now?*/
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    margin: 7px;
    background-color: whitesmoke;
}

.newsPost-body-content > p {
    float: left;
    min-height: 200px; /*subject to change?*/
    padding: 5px;
    font-family: 'PT Sans Caption', sans-serif;
    font-size: large;
}

.news-native {
    max-width: 1100px;
}

.news-side {
    max-width: 500px;
}

.native-image {
    /*margin: 10px auto !important;*/
    /*max-width: 400px;*/
}

/*====================
    MULTI IMG
 ====================*/
/*Box at the bottom of the news post containing image previews if there are at least 1 image on the post.*/
.newsImg_Box {
    width: 100%;
    height: 150px;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    margin: 7px;
    background-color: whitesmoke;
}

/*modifier class to be added to newsImg_Box when working with the multi-image arrangement.*/
.multi {
    display: flex;
    flex-direction: row;
}

.text-only {
    height: 386px; /*same height as image-containing posts.*/
}

.text-only-body {
    height: 100%;
}

/*When multiple images are present, this box contains the large image preview for image index == 0*/
.newsImg_Multi_Primary {
    float: left;
    width: 70%;
}

/*When multiple images are present, this box contains the smaller image previews for images where index > 0*/
.newsImg_Multi_Secondary {
    width: 30%;
    overflow-y: scroll;
}

/*div containing the immediate react-modal-image Image*/
.newsImg_Modal {
    height: 125px;
    margin: 10px;
    padding: 5px;
    border-radius: 0.25rem;
    box-shadow: 3px 3px lightgray;
    /*border: 1px solid lightgray;*/
    overflow: hidden;
}

.newsImg_Modal_Small {
    /*margin: 12px 10px;*/
    margin: 0.73rem 0;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 3px 3px lightgrey;
    /*border: 1px solid lightgray;*/
    overflow: hidden;
    height: 50px;
}

/*NEWS FEED*/

#feed_container {
    background-color: whitesmoke;
    padding: 2%;
    padding-top: 0px;
    width: 100%;
    margin: 0 auto;
    /*height: 1100px;*/
    position: relative;
}

.feed_container.h2 {
    box-shadow: 3px 3px 8px 2px #ccc;
    border-radius: 0.25rem;
    padding: 1rem;
    background-color: #545454;
    font-family: "Playfair Display", serif;
    font-size: 2rem;
    text-align: center;
    /* color: #a8cdc9; */
    color: #febc12;
}

.feed_pagin {
    display: flex;
    justify-content: center;
    box-shadow: 3px 3px 8px 2px #ccc;
    border-radius: 0.25rem;
    padding: 1rem;
    background-color: white;
    /*position: absolute;*/
    /*width: 96%;*/
    bottom: 15px;
}

.feed_icon {
    font-size: 1.25rem;
    background-color: whitesmoke;
    box-shadow: 1px 1px 6px 1px #1c1c1c;
    border: 2px solid #ccc;
    border-radius: 0.75rem;
    color: #545454;
    cursor: pointer;
    margin: 0 10%;
}

.feed_icon:active {
    box-shadow: none;
    border-style: ridge;
}

/*NEWS ARTICLE*/

.article {
    box-shadow: 3px 3px 8px 2px #ccc;
    /*font-family: "Roboto", sans-serif;*/
    border-radius: 0.25rem;
    padding: 2rem;
    background-color: #fff;
    margin-bottom: 1.75rem;
}

.article_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.article_title {
    /*font-family: "Playfair Display", serif;*/
    font-size: 1.5rem;
    margin: 0;
    display: inline;
}

.article_author {
    margin-top: 0;
    color: gray;
    font-size: 1rem;
}

.article_postTime {
    font-size: 0.75rem;
}

.article_del_btn {
    align-self: flex-start;
    margin-top: 0.5rem;
    cursor: pointer;
    text-align: right;
    float: right;
}

.article_content {
    padding-bottom: 0.5rem;
}

.article_imgs {
    text-align: right;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.article_main_img {
    text-align: center;
    width: 73%;
}

.article_thumbs {
    text-align: center;
    padding: 0;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.article.div {
    border: 1px solid #ccc;
    height: 5rem;
}

.news_pagin {
    font-size: xx-large;
    position: absolute;
    right: 25%;
    left: 25%;
}
