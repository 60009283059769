body {
}
.arrow-container {
    /*nifty trick to prevent text selection outside of the clickable space. Works especially well for our case. https://stackoverflow.com/a/54029523*/
    user-select: none;
    cursor: pointer;
    width: 100px;
    height: 50px;
    margin: 0 20px;
}

.arrow-left {
    z-index: 999;
    width: 100px;
    height: 50px;
    margin: auto auto;
    background: transparent;
    transform: scale(0.9)
}

.arrow-right {
    z-index: 999;
    width: 100px;
    height: 50px;
    margin: auto auto;
    background: transparent;
    transform: rotate(180deg) scale(0.9);
}

.arrow-left:hover {
    fill: red;
}

.arrow-right:hover {
    fill: red;
}

.pathOuter {
    fill: lightgrey;
}

.pathInner {
    fill: black;
}


/* =====================
    PAGINATION ELEMENTS
   ===================== */

.pagination-box {
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
}

.pagination-box ul {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0px;
}

.pagination-box li {
    float: left;
    margin: 0 1rem;
    cursor: pointer;
    font-size: x-large;
    width: 55px;
    height: 100%;
    line-height: 2;
    user-select: none;
}

.pagination-box li:hover {
    color: slateblue;
}

/*for small mobile views (very small views removes the number outright)*/
.pagination-box-small li {
    margin: 0;
}

.pagination-number-box {
    display: inline-flex;
    margin: 0 auto;
    height: 100%;
}

.pagination-number-box span {
    font-size: xx-large;
    cursor: pointer;
    width: 50px;
    user-select: none;
}

.pagination-number-box span:hover {
    color: slateblue;
}

.pagination-number-box ul {
    list-style: none;
}

.pagination-number-box > li {
    margin-right: 0.5em;
    color: slateblue;
    font-size: x-large;
    user-select: none; /*dont forget to add this to other elements so this doesn't evoke nostalgia for the internet circa 2005*/
    cursor: pointer;
}

.selectedPageNumber {
    background-color: #dcdcdc;
    color: black;
}

