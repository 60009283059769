// Imports
@import 'GlobalStyles';

#footer {
    background-color: transparent;

    span {
        overflow-wrap: break-word;
    }

    a {
        color: $link;

        &:hover {
            color: $link-hover;
        }

        &:visited {
            color: $link-visited;
        }
    }

    h4 {
        color: $white;
    }

    p {
        color: $main-font-color;
        user-select: none;
    }

    
}

#footer .nav-link{
    display: inline;
}
