.home{
    display: flex;
    /*background-color: #272727;*/
    /*background-color: #fff;*/
    border-radius: 0.5rem;
    width: 100%;
    margin: 0 auto; /*fill content div*/
}

.row-home{
    float: left;
    display: flex;
    flex-direction: column;
    flex: 4;
    background-color: lightgreen;
}

.row-news {
    display: flex;
    flex-direction: column;
    flex: 0;
    background-color: lightyellow;
}

/*===========================
        HOME CONTENTS
============================*/

.homeBody{
    overflow: hidden;
}

#recentOrders {
    width: 100%;
    margin-bottom: 60px;
}

#recentOrders-content {
    height: 100%;
    min-height: 750px;
    margin: 2px; /*let the display items breathe a little*/
    /*overflow: hidden;*/
}

#todaysDeliveries {
    width: 100%;
    height: 40vh;
}

#todaysDeliveries-content {
    color: white;
    height: 100%;
    overflow-y: scroll;
}

/*=========================
    RECENT ORDERS TABS
=========================*/
.nav-recents {
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}

.nav-item-tab-home{
    padding: 0 5px;
}

.nav-link-tab-home {
    display: block;
    padding: 0.5rem 1rem;
    border-bottom: 0px solid black;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    cursor: pointer;
    user-select: none;
    background-color: #d2d2d24a;
}

    .nav-link-tab-home:hover, .nav-link-tab-home:active {
        background-color: #6b6b6b4a;
    }

.tab-sel {
    background-color: #6b6b6b4a;
}