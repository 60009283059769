
.searchFilter {
    width: 100%;
    height: 100%;
    /*display:flex;*/
    position: relative;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
}

.directSearch{
    width: 100%;
    min-height: 80px;
    position: relative;
    display: inline-flex;
    flex-direction: row-reverse;
    padding-right: 10px;
}

.directSearch-wo{
    position: absolute;
    text-align:center;
    min-width: 300px !important;
}

.searchFilter-Inner{
    width:100%;
    margin: 10px;
    padding: 5px;
    user-select: none;
}

.radio{
    display:flex;
    flex-direction:column;
    padding: 5px;
}

.dateRange label{
    margin-right: 2px;
}

.control-group-disabled{
    pointer-events: none;
    opacity: 0.4;
}

.filterResults {
    width: 100%;
    display: block;
    border-top: 1px solid #e0e0e0;
    padding: 10px;
}

.filterResults>h4{
    padding-left:10px;
}

.filterControls{
    display: flex;
    flex-direction: row;
    width: 90%;
}

.filterTextFields {
    display: flex;
    flex-direction: column;
}

#submitFilterBtn{
    position: absolute;
    right: 100px;
    bottom: 0px;
    margin: 10px;
}

#clearFilterBtn{
    position: absolute;
    right: 189px;
    bottom: 0px;
    margin: 10px;
}

#submitSearchBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
}

.filterIndicators label{
    font-size: 16px;
    max-width: 250px;
}

.filterIndicators ul{
    display: flex;
    list-style: none;
}

.filterIndicators li{
    display: inline;
}