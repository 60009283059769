﻿// Variables ------------------------------------->
// Colors
$white: #FFFFFF;
$black: #000000;
$duskblue: #717AAB;
$dark-gray: #252525;
$medium-gray: #545454;
$seafoam: #a6ccc8;
$off-white: #ecf0f1;
$whitesmoke: whitesmoke;
$bronze: #b29c87;
$ltblue: #8DD6EA;
$dkblue: #3c6872;

$interactable: #11497E; //med-dark blue used for primary controls
$interactable-light: #408CCB; //highlight of "interactable"
$interactable-lighter: #78bbf3; //note: this color added by a programmer, not a designer 
$menu-icon: #9D9D9D; //med gray color for the icons on the sidebar
$menu: #686969; //dark gray for the sidebar background
$menu-btn: #D3D3D3; //light gray
$subheader: #020202; //nearly black
$footer-dark: #333333;

// Fonts
$default-font-color: $black;
$main-font-color: $white;
$secondary-font-color: $duskblue;
$tertiary-font-color: $bronze;
$mute-font-color: $medium-gray;

$link: $interactable-light;
$link-visited: $interactable-lighter;
$link-hover: $white;


// BG Colors
$neutral-light-bgcolor: $whitesmoke;
$dark-bgcolor: $dark-gray;
$medium-bgcolor: $medium-gray;
$main-accent-bgcolor: $duskblue;
$bg-highlight: $off-white;

