/*
    myorders
        row-search
        row-body
            col-orders
            col-details
                row-info
                (white space where other row would be)
        row-pagination
*/

@import './layout/GlobalStyles';

.myorders {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    padding: 0px 10px 0px 10px;
}

.loading {
    width: 100%;
    height: 100%;
    line-height: 25; /*this resolves to a percentage value, yet putting 25% not working(?)*/
    text-align: center;
    font-size: xx-large;
}

.row-search {
    display: flex;
    flex-direction: row-reverse;
}

.row-search form {
    margin: 5px;
}

.row-search label {
    font-weight: bold;
}

.row-search input {
    padding-left: 10px;
    /*border: 1px solid black;*/
    border-radius: 5px;
}

.row-body {
    display: flex;
    flex-direction: row;
    padding: 15px 0px;
    /*min-height: calc(90vh - 70px);*/ /*prev - 150px*/
    min-height: 74vh;
}

.row-pagination {
    display: flex;
    flex-direction: row;
    height: 50px;
    margin-bottom: 10px;
}

/*mobile-friendly containing body for the orders selection. includes header*/
.col-orders {
    background-color: white;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 10px;
    /*min-height: inherit;*/
    min-height: 735px;
    padding: 5px;
}

.col-orders-print {
    background-color: white;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 10px;
    min-height: inherit;
    margin-right: 5px;
}

/*mobile-friendly containing body for order details. includes header*/
.col-details {
    position: relative;
    background-color: white;
    /*margin-left: 10px;*/
    border: 1px solid black;
    border-radius: 10px;
    /*min-height: inherit;*/
    min-height: 735px;
}

h3.col-details {
    user-select: all !important;
}

h4.col-details {
    user-select: all !important;
}

/*container for orders elements, does not include header*/
#container-orders {
    display: flex;
    flex-direction: column;
    /*max-width: 50%;*/
    width: 50%;
    margin: 0 auto;
    overflow-x: hidden;
    /*overflow-y: scroll;*/
    min-height: inherit;
    /*color: $secondary-font-color !important;*/
}

#container-orders-header {
    color: $secondary-font-color !important;
}

.mobile-orders {
    width: 100% !important;
}

.mobile-details {
    width: 100% !important;
    z-index: 9999 !important;
    position: absolute;
    margin: 0 0 !important;
}

.mobile-details > h4 {
    background-color: #272727;
    width: 100%;
    text-align: center;
}

/*container for order details elements, does not include header*/
#container-details {
    display: flex;
    flex-direction: column;
    width: 50%;
    /*margin-left: 10px;*/
    overflow-x: hidden;
    min-height: inherit;
    /*color: $secondary-font-color !important;*/
}

#container-details-header {
    color: $secondary-font-color !important;
}

.container-subheader {
    margin: 0 auto;
    /*padding: 10px;*/
}

.row-info {
    display: flex;
    flex-direction: row;
    background-color: orange;
    width: 100%;
    height: 50%;
}

#orderslist {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    margin: 5px;
    background-color: purple;
}

#details {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-shrink: 1;
    height: 50%;
    padding: 10px;
    width: 40%;
    margin: 5px;
    background-color: orange;
}

#orderDetailsPlaceholder {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
}

#orderDetailsPlaceholder h3 {
    margin: auto auto; /*centers the "select an order" message in the details box*/
}

.order {
    flex: 1;
}

.details h3 {
    text-align: center;
    margin: 5px;
}

.order-img {
    padding: 5px;
    float: left;
    width: 128px;
    height: 128px;
    max-width: 100px;
    max-height: 100px;
}

.order table tbody tr {
    padding: 2px;
}

/* =====================
    Search Tool V2
   ===================== */

/*main search and filter container which sits on top of order results.*/
#controlPanel {
    width: 100%;
    height: 100%;
    padding: 10px;
    /*display:flex;*/
    position: relative;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
}

#searchRow {
    position: relative;
    border-bottom: 1px solid #ecebeb;
    /* margin-bottom: 15px; */
    padding-bottom: 5px;
    margin: 5px -5px;
}

#filterRow {
}

#dateTypePickerContents {
    width: 100%;
    min-height: 80px;
    text-align: right;
    padding: 10px;
    /*margin: 0 5%;*/
}

.loading-fade {
}

.myOrders-results-count {
    position: absolute;
    bottom: 0px;
    right: 10px;
}

#print_table {
    width: 100%;
    height: 100%;
    left: 15px;
    position: absolute;
}
