@import './layout/GlobalStyles';

.loadingBackground {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    background-color: rgba(185, 185, 185, 0.65);
}

/*===========================
    CLASSIC LOADING WHEEL
============================*/

.loading_area {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.loading_background {
    background-color: rgba(0,0,0,0.4);
}

.loading_wheel_inner {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 20px;
    border-radius: 50%;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.loading_wheel_outer {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 20px;
    border-radius: 50%;
    animation-name: spin-reverse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.wheel_container .loading_wheel_outer {
    border-style: double;
    border-color: #525252 transparent;
}

.wheel_container .loading_wheel_inner {
    border-style: double;
    border-color: $tertiary-font-color transparent;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes spin-reverse {
    from {
        -webkit-transform: rotate(0);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

/*===========================
    WATER BALL
============================*/
@import url(https://fonts.googleapis.com/css?family=Droid+Sans);

.waterBackground {
    background-color: gray;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Droid Sans';
    position: absolute;
}

.waterBox {
    display: flex;
    flex-direction: column-reverse;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto auto;
    border: 2px solid black;
    background-color: white;
}

.waterBody {
    height: 100px;
    width: 100px;
    animation: 6s rise-frust infinite;
}

.blackDroplets {
    position: absolute;
    width: 96px;
    height: 82px;
    margin: auto auto;
    padding-bottom: 22px;
    z-index: 999;
    overflow: hidden;
}

@keyframes rise-frust {
    0% {
        height: 20%;
        background-color: cyan;
    }

    50% {
        height: 94%;
        background-color: cyan;
    }

    100% {
        height: 20%;
        background-color: cyan;
    }
}
