/*==============================
    Main Bodies
  ==============================*/

#employee-body {
    min-width: 75%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
}

#employee-header {
    height: 50px;
    width: 100%;
    text-align: center;
}

.panel {
    padding: 15px 5px;
}

    .panel > h3 {
        border-bottom: 1px solid black;
        text-align: center;  
    }

/*==============================
    Specific Panels
  ==============================*/

.panel-postBlog {
    height: 600px;
    min-width: inherit;
}

    .panel-postBlog img {
        background-repeat: no-repeat;
    }

#current_registered_for_customer {
    min-height: 100px;
    padding: 25px 0px;
}
/*==============================
    Blog Post Interface
  ==============================*/

.blogForm{
    margin: 0 auto;
}


/*==============================
    Misc Controls
  ==============================*/