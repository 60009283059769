
@import './layout/GlobalStyles';

/*==================================
        Display Items
===================================*/

.selectedOrder {
    box-shadow: 3px 3px 8px 2px gray;
    background-color: $interactable-light !important;
}

.display_box {
    color: $white;
    display: flex;
    justify-content: space-between;
    align-content: center;
    box-shadow: 3px 3px 8px 2px #ccc;
    font-family: "Roboto", sans-serif;
    border-radius: 1.5rem;
    padding: 0.5rem;
    background-color: $interactable;
    margin-bottom: 0.75rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    margin-right: 5px;
}

.display_box:hover {
    box-shadow: 3px 3px 8px 2px gray;
    /*background-color: #e8e8e8;*/
    background-color: $interactable-light;
}

.display_image {
    float: left;
    margin: 5px;
    height: 50px !important;
    width: 50px !important;
    border-radius: inherit;
}

.display_image svg {
    width: 100% !important;
    height: 100% !important;
}

.display_details {
    display: inline-flex;
    flex: 1;
    width: 100%;
    height: 100%;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    position: relative;
}

.display_text {
    border-radius: 5px;
    width: 90%;
    height: 100%;
    margin: -1px; /*handles some of the border overlaps (except for top and bottom which look kind of nice actually)*/
    overflow-y: hidden;
    padding-left: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.display_indicators {
    width: 72px;
    height: 150%;
    position: absolute;
    right: 5px;
    font-size: large;
    right: -10px;
    top: -10px;
    background-color: $white;
}

/* =============================== *
 *      Display Items (Mobile)     *
 * =============================== */

.display_box_mobile {
    display: flex;
    justify-content: space-between;
    align-content: center;
    box-shadow: 3px 3px 8px 2px #ccc;
    font-family: "Roboto", sans-serif;
    border-radius: 0.25rem;
    background-color: #fff;
    margin-bottom: 0.75rem;
    overflow: hidden;
}

display_box_mobile:hover {
    box-shadow: 3px 3px 8px 2px gray;
    background-color: #e8e8e8;
}

.display_details {
    display: inline-flex;
    flex: 1;
    width: 100%;
    height: 100%;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    position: relative;
}

.display_details > h5 {
    margin-bottom: 1px;
    bottom: 2px;
    right: 5px;
    width: 100%;
}

/*mobile detail misc*/
.mobile_detail_x {
    position: absolute;
    top: -5px;
    right: 10px;
    font-size: xx-large;
    cursor: pointer;
}

.mobile_detail_x:hover, .mobile_detail_x:active{
    color: $secondary-font-color;
}

.display_text_mobile {
    border-radius: 5px;
    width: 90%;
    height: 100%;
    margin: -1px; 
    overflow-y: hidden;
}

.display_text_mobile > h5 {
    margin-bottom: 2px;
    font-family: Arial; 
}

.display_text_mobile > span {
    margin: 0px 10px;
    word-break: break-all; 
}

.subtext_table {
    font-size: small;
    margin-bottom: 3px;
}

.subtext_table > tbody > tr > td:first-child {
    min-width: 30%;
    font-weight: bold;
    width: 95px;
}

.subtext_table > tbody > tr > td:last-child {
    min-width: 70%;
    font-style: italic;
}

.subtext_table > tbody > tr > td:nth-child(2n+1) {
    user-select: none;
}

.display_indicators_mobile {
    padding: 1px;
    width: 82px;
    background-color: #bcbcbc;
    position: absolute;
    right: 0;
    width: 35px;
    font-size: small;
}

.display_indicators_prog {
    list-style: none;
    text-align: center;
    margin-bottom: 0px;
    font-size: x-large;
    padding: 0px;
}

.display_indicators_prog_mobile {
    list-style: none;
    text-align: center;
    margin-bottom: 0px;
    font-size: small;
    padding: 0px;
}

.display_indicators_other_mobile {
    columns: 1;
    list-style: none;
    text-align: center;
    padding: 0px;
}

.display_indicators_other {
    columns: 2;
    column-gap: 4px;
    list-style: none;
    text-align: center;
    padding: 0px;
}

/* =============================== *
 *      Details                    *
 * =============================== */

.detail_box {
    font-family: Arial;
    /*position: absolute;*/
    width: 100%;
    height: 100%;
    padding: 5px;
    overflow-x: hidden;
}

.detail_box > h1, .detail_box > h2, .detail_box > h3, .detail_box > h4, .detail_box > h5 {
    /*color: $tertiary-font-color;
    font-family: Arial;*/
    font-family: 'Lato Heavy';
}

.order_table {
    border: 1px solid black;
    width: 100%;
}

.detail_items {
    width: 100%;
}

.detail_items > tbody > tr:nth-child(odd) {
    /*background-color: lightgrey;*/
    background-color: #eaeaea;
}

.detail_items td:last-child, .detail_items th:last-child{
    text-align: right;
    padding-right: 5px;
}

/*=================================
    ITEMS ON ORDER DETAIL TABLE
=================================*/

table.order_table {
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #000000;
    background-color: #DCE8FF;
    width: 100%;
    text-align: left;
}

table.order_table td, table.order_table th {
}

table.order_table tbody td {
    font-size: 13px;
}

table.order_table tr:nth-child(even) {
    background: #ECF1F5;
}

table.order_table thead {
    background: #DDDDDD;
    border-bottom: 2px solid #444444;
}

table.order_table thead th {
    font-size: 15px;
    font-weight: bold;
}

table.order_table tfoot {
    font-size: 14px;
    font-weight: bold;
    background: #DDDDDD;
    background: -moz-linear-gradient(top, #e5e5e5 0%, #e0e0e0 66%, #DDDDDD 100%);
    background: -webkit-linear-gradient(top, #e5e5e5 0%, #e0e0e0 66%, #DDDDDD 100%);
    background: linear-gradient(to bottom, #e5e5e5 0%, #e0e0e0 66%, #DDDDDD 100%);
    border-top: 2px solid #444444;
}

table.order_table tfoot td {
    font-size: 14px;
}

/*==============================
    ORDER LOOKUP CONTAINER
===============================*/

.orderSearch {
    border-bottom: 1px solid black;
    padding: 5px;
    display: flex;
    margin: 0 auto;
    position: relative;
    height: 120px;
}

.orderLookupContainer {
    width: 100%;
    margin: 15px;
    background-color: white;
    border: 1px solid black;
    border-radius: 1em;
    padding: 15px;
    position: relative;
}

.orderLookupBody {
    width: 100%;
    background-color: white;
    position: relative;
    padding: 15px;
}

/*==============================
    ORDER PDF IMAGE
===============================*/

.pdfFrame {
    height: 100%;
    width: 100%;
}

.pdfTarget {
    height: 1100px;
    width: 850px;
    position: absolute;
    margin: 0;
    padding: 0;
}