@import './layout/GlobalStyles';

.deliveries {
    color: #545454;
    font-size: x-large;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
}

.deliveries-header {
    margin: 0 auto;
    /*display: inline-flex;*/
    /*min-width: 50vw;*/
    font-size: larger;
}

#deliveryDateBox {
    font-family: sans-serif;
    color: white;
    background-color: $interactable;
    border-width: 2px;
    border-style: double;
    border-color: white;
    border-radius: 10px;
    width: 400px;
    min-height: 60px;
    margin: 0 auto;
    display: inline-flex;
}

.deliveryDate {
    text-align: center;
    vertical-align: middle;
    margin: auto auto;
    padding: 10px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.deliveries-header.deliveries-nav-arrow#left {
    float: left;
}

.deliveries-header.deliveries-nav-arrow#right {
    float: right;
}

.deliveries-body {
    /*margin: 10px 40px;*/
    min-height: 80vh;
    /*background-color: #242424;*/
    border: 1px solid #545454;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    padding: 2%;
}

.arrow {
    font-size: 50px;
    cursor: pointer;
    user-select: none;
}

.arrow:hover {
    color: $interactable-light; /*CHANGE THIS TO TERTIARY FONT COLOR FROM SASS ONCE THAT'S WORKING*/
}

.left {
    margin-right: 15px;
    /*text-shadow: -4px 4px black;*/
}

.right {
    margin-left: 15px;
    /*text-shadow: 4px 4px black;*/
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
    color: #ccc;
}

.datePickerNav {
    width: 250px;
    margin: 0 auto;
}

.dateBoxNav {
    display: inline-flex;
    /*width: 100%;*/
}

/* =============================== *
 *      DELIVERY TABLE CSS         *
 * =============================== */

.ordersTableBox {
    margin: 15px;
}

.deliveryOrdersTable {
    width: 100%;
    height: 100%;
    table-layout: auto;
}

.deliveriesTable-TotalsHeader {
    margin: 10px;
}

.deliveriesTable-TotalsHeader-Value {
    margin-left: 10px;
}

.deliveryOrdersTable thead th {
    font-weight: bold;
    font-size: large;
    user-select: none;
}

.deliveryOrdersTable tr {
    white-space: nowrap;
}

.deliveryOrdersTable thead tr {
    border-bottom: 1px solid #545454;
}

.deliveryOrdersTable tr th:last-child {
    /*text-align: center;*/
}

.deliveryOrdersTable tr td {
    font-size: small;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
}

.deliveryOrdersTable tr td:last-child {
    /*text-align: right;*/
    margin-right: 15px;
}

.deliveryOrdersTable tfoot tr td {
    /*note that rowspan can just be set in the jsx file as opposed to the css one*/
    text-align: right;
}

.delivery-header{
    margin-right: 5px;
}

.grandTotalValue {
    line-height: 2.5rem;
}


@media (min-width: 1025px) {
    .deliveryOrdersTable {
        font-size: medium;
    }
}

@media (max-width: 1024px) {
    .deliveryOrdersTable {
        font-size: smaller;
    }

    .deliveryOrdersTable tr th {
        font-size: small;
    }

    .deliveryOrdersTable tr td {
        text-align: right;
    }

    .deliveryOrdersTable tr td:first-child {
        text-align: left;
    }
}

/* ======================================= *
 *      DATEPICKER BLACKOUT OVERRIDE       *
 * ======================================= */

/*they told me to paint it black*/
.react-datepicker {
    background-color: #545454 !important;
    border: 1px solid white !important;
    color: white !important;
    height: 275px; /*so it doesn't need a containing div to manage resizing*/
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.react-datepicker__header {
    background-color: #545454 !important;
    border-bottom: 1px solid whitesmoke !important;
}

react-datepicker__month {
    background-color: #545454 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: whitesmoke !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: whitesmoke !important;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text {
    user-select: none; /*still allows onChange but stops text selection.*/
}
