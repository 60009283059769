// Imports
@import 'GlobalStyles';

.topbar {
    /*background: #545454;*/
    /*background: $interactable;*/
    background: rgb(128,164,199);
    background: radial-gradient(circle, rgba(128,164,199,1) 0%, rgba(17,73,126,1) 50%);
    /*background: url(/images/testgraysample.png);
    background-repeat: repeat;
    background-size: contain;*/
    width: 100%;
    height: 89px;
    color: $secondary-font-color;
    display: flex;
    justify-content: center;
}

.topbar-header {
    height: 100%;
    text-align: center;
    vertical-align: middle;
    color: $secondary-font-color;
    height: 100%;
    position: relative;
    top: 14%;
    line-height: 50px;
}

.toplink {
    color: #FFF;
}

.toplink:hover {
    background-color: $medium-bgcolor;
    color: slateblue;
}

.xOut {
    background: $main-accent-bgcolor;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.xOut svg {
    width: 200px;
    height: 150px;
    cursor: pointer;
    transform: scale(0.5);
}

.xOut path {
    fill: none;
}

.toggle-tab {
    width: 50px;
    position: absolute;
    left: 29px;
    top: 22px;
}

.toggle-tab .toggle-tab-line {
    width: 40px;
    height: 3px;
    background-color: $bg-highlight;
    display: block;
    margin: 7px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.toggle-tab:hover .toggle-tab-line {
    background-color: $interactable-light;
    /*background-color: #dcdcdc;*/
}

.mobile-toggle {
    z-index: 999;
}

.toggle-tab.is-active .toggle-tab-line:nth-child(2) {
    opacity: 0;
}

.toggle-tab.is-active .toggle-tab-line:nth-child(1) {
    -webkit-transform: translateY(10px) rotate(45deg);
    -ms-transform: translateY(10px) rotate(45deg);
    -o-transform: translateY(10px) rotate(45deg);
    transform: translateY(10px) rotate(45deg);
    border: 0px transparent solid;
}

.toggle-tab.is-active .toggle-tab-line:nth-child(3) {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
    border: 0px transparent solid;
}
