﻿// Imports
@import "variables";

// Fonts
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption|Roboto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

// Flex Column
@mixin flex-column($justify: flex-start, $align: flex-start) {
    display: flex;
    flex-direction: column;
    justify-content: $justify;
    align-items: $align;
}

// Flex Row
@mixin flex-row($justify: flex-start, $align: flex-start) {
    display: flex;
    flex-direction: row;
    justify-content: $justify;
    align-items: $align;
}

// Headlines
h1, h2, h3, h4, h5 {
    /*font-family: 'Archivo Black', sans-serif;*/
    font-family: 'Playfair Display Bold', serif;
    /*font-family: Arial;*/
    user-select: none;
    /*color: $interactable-light;*/
    /*color: $secondary-font-color;*/
    /*text-shadow: 2px 2px $dark-gray;*/
    /*-webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;*/
}

h4, h5 {
    /*font-family: 'Playfair Display Bold', sans-serif;*/
    font-family: 'Lato Heavy';
    /*color: $tertiary-font-color;*/
}

h2 {
    font-size: 2rem;
}

@font-face {
    font-family: 'Nobel Regular';
    src: url ("/fonts/nobel-regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Nobel Bold';
    src: url ("/fonts/nobel-bold.ttf") format('truetype');
}

@font-face {
    font-family: 'Lato Regular';
    src: url ("/fonts/Lato-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Lato Heavy';
    src: url ("/fonts/Lato-Heavy.ttf") format('truetype');
}


body {
    height: 100%;
    margin: 0;
    /*font-family: "Roboto", sans-serif;*/
    /*font-family: 'Nobel Regular' sans-serif;*/
    color: $default-font-color;
}

.layout {
    display: flex;
    flex-direction: column;
    margin: 0;
    background-image: url ("../../../public/images/acid-etch-2.jpg");
    /*background-image: url("/images/testImg.webp");*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.row-main {
    display: flex;
    flex-direction: row;
    flex: 5;
}

.row-foot {
    display: flex;
    flex-direction: row;
    flex: 1;
    /*background: url(/images/metalFinish2.png);
    background-repeat: repeat;
    background-size: contain;*/
    background: $footer-dark;
    color: #fff;
    padding-top: 45px;
}

.col-side {
    display: flex;
    flex-direction: column;
    flex: 0;
}

.col-main {
    display: flex;
    flex-direction: column;
    flex: 6;
    flex-shrink: 0;
}

.content {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: 100%;
    padding-top: 5px; /*if this becomes a problem, just manage it from the child element*/
    margin-right: auto;
    background-color: whitesmoke;
    margin-bottom: 40px;
}


@media (min-width: 1025px) {
    .content {
        margin-left: auto !important;
    }
}